@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './search_filter.scss';
@import './reports.scss';
@import './scrollbar.scss';

@font-face {
  font-family: 'poppins';
  src: local('poppins'), url('../fonts/poppins.ttf') format('truetype');
}

.MuiBackdrop-root {
  background: rgba(255, 255, 255, 0.1);
}
.err-box {
  background: rgba(255, 87, 87, 0.2);
  border: 0.5px solid #ff5757;
  border-radius: 12px;
  padding: 4px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .closeIcon {
    cursor: pointer;
  }
  .msg {
    width: 90%;
    white-space: break-spaces;
    padding: 4px;
  }
}
.success-box {
  background: rgba(84, 201, 117, 0.2);
  border: 0.5px solid #54c975;
  border-radius: 12px;
  padding: 4px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .closeIcon {
    cursor: pointer;
  }
  .select-text {
    font-size: 13px !important;
  }
  textarea:focus,
  input:focus {
    outline: none !important;
  }
  input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .msg {
    width: 90%;
    white-space: break-spaces;
    padding: 4px;
  }
}

/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root,
input {
  background: inherit !important;
  color: var(--input-text-color) !important;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;

  font-size: 14px;
  height: 42px;
  padding: 0;
  box-sizing: border-box;
  border-radius: 8px;
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;
    padding: 14px 16px;
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

input::placeholder {
  // border: 1px solid var(--ion-background) !important;
  color: var(--ion-text-light);
}

.sc-ion-input-md-h input {
  height: 34px;
  border-radius: 8px !important;
  color: var(--text) !important;
}

.input-label {
  color: var(--text);
  display: flex;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--text4) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/** Modal styles **/
.modal-title {
  .MuiTypography-root {
    font-size: 32px !important;
  }
}

.modal-content-ctn {
  input:focus {
    outline: none !important;
  }
}

.mouse-pointer {
  cursor: pointer;
}

/* Punter reports styles */

.admin-module-ctn {
  margin-left: 2%;
  margin-right: 2%;
  @media screen and (max-width: 720px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
    display: block;
    width: 100vw;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.select-text {
  font-size: 12px !important;
}
.full-width {
  width: 100%;
}

.no-txt-wrap {
  white-space: nowrap !important;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.b-text {
  font-weight: 600 !important;
}

.profit,
.success {
  color: var(--ion-color-success) !important;
}

.loss {
  color: var(--ion-color-danger) !important;
}
.error-msg {
  color: var(--ion-color-danger) !important;
}

.m-link {
  cursor: pointer !important;
}

@media only screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media only screen and (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

.site-logo {
  width: var(--logo-xs-width);
  height: var(--logo-xs-height);
  margin-top: 4px;
}
.select-text {
  color: var(--table-text) !important;
}
.MuiAppBar-colorPrimary {
  background-color: var(--ion-header-background) !important;
  z-index: 9999;
}

.button-small,
.button-solid {
  font-family: var(--font-family) !important;
  color: var(--common-dark) !important;
  --border-radius: 10px !important;
  height: 34px !important;
  @media (max-width: 720px) {
    height: 30px !important;
  }
}
body {
  font-family: var(--font-family) !important;
}
div,
p,
span {
  font-family: var(--font-family) !important;
}
.MuiPaper-root .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head {
  font-family: var(--font-family) !important;
}
.sc-ion-label-ios-h {
  font-family: var(--font-family) !important;
}

.ion-list-item {
  font-family: var(--font-family) !important;
}
.item .sc-ion-label-md-h {
  font-family: var(--font-family) !important;
}
.MuiButton-containedSizeSmall {
  background-color: var(--ion-color-primary) !important;
}

.MuiSelect-select:focus {
  background: none !important;
}
input[readonly] {
  opacity: 1;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}