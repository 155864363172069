.MuiPopover-root {
    .MuiPopover-paper {
        background: var(--ion-popover-background);
        .MuiList-root,
        .MuiMenu-list {
            padding: 0px;
            border: 0px !important;
            background: var(--select-element-background);
            .MuiListItem-root,
            .MuiMenuItem-root {
                color: var(--ion-text-color);
                background: var(--select-element-background);
                font-size: 14px;
                font-family: var(--font-family) !important;
                padding-left: 10px;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.12);
                }
                &.Mui-selected {
                    border-left: 4px solid var(--ion-color-primary) !important;
                }
            }
        }
    }
}
