.MuiAccordion-root {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 8px !important;
  background: var(--table-body-background);
  .MuiAccordionSummary-root {
    font-style: normal;
    box-sizing: border-box;
    background: var(--bg4) !important;
    color: var(--text) !important;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px !important;
    text-transform: capitalize;
    align-items: center;
    padding: 6px !important;
    margin-top: 0px;
    height: 40px !important;
    min-height: 40px !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    .icon-ctn {
      background-color: var(--common-dark);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 30px;
      border-radius: 8px;
      margin-right: 5px;

      .sport-inplay-icon {
        height: 17px;
        width: 17px;
        margin-right: 8px;
      }
    }
    .MuiIconButton-root {
      width: 14px;
      padding: 0px !important;
      margin-right: 4px !important;
    }
    .MuiSvgIcon-root,
    svg {
      fill: var(--text);
      color: var(--text) !important;
    }
    &.Mui-expanded {
      color: var(--text);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      svg {
        fill: var(--text);
      }
    }
  }
  .MuiCollapse-wrapperInner,
  .MuiAccordionDetails-root {
    display: initial;
    padding: 0;
    background: var(--accordion-details-root-background) !important;
  }
}

.MuiDialog-paper {
  .MuiAccordionDetails-root {
    font-size: 14px;
    padding-left: 10px;
    background: var(--ion-background);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

@media only screen and (max-width: 720px) {
  .MuiAccordion-root {
    margin-top: 2px;
    .MuiAccordionSummary-root {
      padding: 4px;
      font-size: 13px;
      line-height: 17px;
      height: 38px !important;
      min-height: 38px !important;
    }
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
}

.MuiAccordionSummary-content {
  font-size: 13px;
  font-family: var(--font-family);
  font-weight: 600 !important;
}
.MuiAccordion-root .MuiCollapse-wrapperInner,
.MuiAccordion-root .MuiAccordionDetails-root {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
