.MuiDrawer-root {
  .MuiDrawer-paper {
    background: var(--ion-color-primary) !important;
    color: var(--text-white);
    border-radius: 0px !important;
    z-index: 100000;
    max-width: 70px;
  }
}

.makeStyles-drawerClose-8 {
  width: 55px !important;
  // background: var(--ion-color-primary) !important;
}

@media (max-width: 720px) {
  .MuiDrawer-root {
    display: none;
  }
}
