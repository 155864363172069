@import '../src/assets/global_styles/index.scss';
@import './scorcardtheme.css';

.router-ctn {
  width: 100%;
}

.dev-tools-msg-modal {
  .modal-content-ctn {
    background-color: var(--modal-bg-img1);
    height: 320px;
    max-height: 320px;
    overflow: hidden;
    color: var(--ion-text-white);
    @media screen and (max-width: 720px) {
      background-color: var(--modal-bg-img2);
      height: 350px;
      max-height: 350px;
    }
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      overflow: hidden;
      top: 0;
      right: 0;
      border-radius: 0 4px 0 32px;
      background-color: var(--ion-color-primary);
      cursor: pointer;
      .go-arrow {
        // margin-top: -4px;
        // margin-right: -4px;
        color: var(--tab-text) !important;
      }
    }
    .dev-tools-warning-msg {
      height: 280px;
      background-image: var(--modal-bg-img) !important;
      background-repeat: no-repeat;
      background-position: center;
      .msg {
        font-size: 16px;
        color: var(--text-theme);
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        text-align: center;
        left: 15%;
        margin-top: 160px;
        @media screen and (max-width: 720px) {
          left: 10%;
          margin-top: 180px;
        }
        .dsc {
          line-height: 30px;
          color: var(--text-theme);
          font-weight: bold;
        }
        .dsc-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 20px;
          line-height: 23px;
          font-size: 11px;
          color: var(--text-theme);
          font-weight: bold;
          .btn {
            background-color: #279b4e !important;
            width: 25%;
            text-align: center;
            border-radius: 0px;
            margin-top: 10px;
            color: var(--tab-text);
            margin-right: 8px;
            @media screen and (max-width: 720px) {
              width: 35%;
            }
          }
          .btn1 {
            width: 25%;
            text-align: center;
            margin-top: 10px;
            color: #fff !important;
            margin-right: 10px;
            border-radius: 0px;
            background-color: var(--ion-color-primary);
            @media screen and (max-width: 720px) {
              width: 35%;
            }
          }
        }
        .btn {
          background-color: var(--active-tab);
          width: 25%;
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .makeStyles-drawerHeader-8 {
    min-height: 100px !important ;
  }
  .root-div{
    display: block !important;
  }
  .app-header{
    width: 100% !important;
  }
  .app-main{
    padding: 0px !important;
  }
}
.modal .modal-content .dev-tools-warning-msg .msg .dsc {
  color: var(--text) !important;
}
