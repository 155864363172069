/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* Gradient styles */
  /* Theme colors */
  --th1-primary: #db3732;
  --th1-bg: #202020;

  --th2-primary: #ffc019;
  --th2-bg: #f6f6f4;

  --th3-primary: #64d0b7;
  --th3-bg: #232035;

  --th4-primary: #7033f5;
  --th4-bg: #181429;

  --th5-primary: #53ade4;
  --th5-bg: #303031;

  --th6-primary: #db3732;
  --th6-bg: #202020;

  --th7-primary: #c73e8d;
  --th7-bg: #1a2538;

  --th8-primary: #c73e8d;
  --th8-bg: #303031;

  --th9-primary: #18bb4e;
  --th9-bg: #131416;

  --th10-primary: #a66a0e;
  --th10-bg: #131416;
  --login-card: url('../assets/images/common/logincard.png');
  --active-color: #836822;
  --common-dark: #000;
  --login-input-bg: #202020;
  --back-odd-background: #59ceff;
  --lay-odd-background: #ff8a71;
  --color-text: #616161;
  --color-text-btn: #000;
  --card1-gradient-color1: #f12711;
  --card1-gradient-color2: #f5af19;
  --card2-gradient-color1: #7f00ff;
  --card2-gradient-color2: #e100ff;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;

  /** primary **/
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--gradient-color-primary) 3.56%,
    var(--gradient-color-secondary) 97.13%
  );
  --ion-card-gradient: linear-gradient(180deg, #444444 0%, #111111 100%);
  --ion-color-primary: #db3732;
  --ion-color-primary-contrast: #fff;
  /** secondary **/
  /* --ion-color-secondary: #37446e
   */

  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #279b4e;
  --ion-color-success-rgb: 23, 221, 23;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #aa151c;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #aa151c;
  --ion-color-danger-tint: #aa151c;
  --ion-color-lost: #cf022d;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Sports List Navbar Colors **/
  --ion-nav-card-background: var(--ion-color-primary);

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;

  /** Table colour **/
  --table-headers-light: rgba(32, 32, 32, 0.6);
  --table-header-background: #262626;

  /** Reports style **/
  --reports-bg-color: #ffffff;
  --page-titles-color: #ffffff;
  --reports-title-color: #ffffff;
  --filter-controls-border: rgba(255, 255, 255, 0.1);
  --date-color: rgba(255, 255, 255, 0.4);
  --flag-bg: #191919;

  /* Dashboard */
  --summary-card-text-color: #fff;
  --loss-text-color: #ff5757;

  /** Header Logo Sizes **/
  --logo-xl-width: 60px;
  --logo-xl-height: 40px;
  --logo-lg-width: 52px;
  --logo-lg-height: 40px;
  --logo-md-width: 40px;
  --logo-md-height: 40px;
  --logo-sm-width: 40px;
  --logo-sm-height: 40px;
  --logo-xs-width: 56px;
  --logo-xs-height: 35px;

  /** Login Logo Sizes **/
  --login-logo-width: 192px;
  --login-logo-height: 93px;
}

.ios body.dark,
.md body.dark {
  --ion-text-color:#fff;
  --color: #fff;
  --common-dark: #000;
  --input-bg: var(--ion-background);
  --bg1: #000;
  --bg2: #db3732;
  --bg3: #303030;
  --bg4: rgba(255, 255, 255, 0.1);
  --bg5: rgba(255, 255, 255, 0.12);
  --text: rgba(255, 255, 255, 0.4);
  --text1: rgba(255, 255, 255, 0.4);
  --text2: #8f8f8f;
  --text3: rgba(255, 255, 255, 0.6);
  --text4: rgba(255, 255, 255, 1);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --label-color: rgba(255, 255, 255, 0.8);
  --ion-background: #19191b;
  --profile-card: linear-gradient(180deg, #392727 0%, #100a0a 100%);
  --profile-tab: #2f2e2e;
  --modal-bg-img: url('../assets/images/common/modal1.png');
  --modal-bg-img2: url('../assets/images/common/modal11.png');
  --modal-bg-img1: #231f20;
  --filter-border: rgba(255, 255, 255, 0.1);
  --filter-border1: rgba(255, 255, 255, 0.1);
  --report-table-header: linear-gradient(180deg, #474747 0%, #121111 100%);
  --report-table-border-color: #734a4b;
  --disable: rgba(255, 255, 255, 0.2);
  --disable-text: #fff;
  --table-border-color: #734a4b;
  /* --font-family: 'Istok Web', sans-serif; */
  --font-family: 'Poppins';
  --ion-card-bg: linear-gradient(180deg, #474747 -50%, #121111 130.04%),
    linear-gradient(0deg, #734a4b, #734a4b);
  --select-element-background: #231f20;
  --ion-header-background: #19191b;

  --text-color: #000;
  --ion-color-secondary: #202020;
  --ion-background-color-rgb: #19191b;
  --ion-background-color: #19191b;
  --sub-header-bg-color: #db3732;

  --ion-background-secondary: rgba(255, 255, 255, 0.1);
  --ion-background-tertiary: #fff;

  --ion-text-light: #e4ebff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  /** Card Variables**/
  --ion-card-background: #231f20;
  --ion-card-background-secondary: #231f20;
  --ion-card-background-contrast: #e4ebff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* drawer */

  --ion-drawer-background: linear-gradient(180deg, #444444 0%, #111111 100%);
  --text-white: #fff;
  /** Popover Variables**/
  --ion-popover-background: #000;
  --ion-popover--hover-background: #444444;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #231f20;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-content-background: #231f20;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #231f20;
  --date-picker-header-button-background: #231f20;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #fff;

  /** Input Field Styles **/
  --input-background-color: rgba(255, 255, 255, 0.1);
  --input-text-color:#000;
  --table-header-text: #fff;
  /* --odd-table-row: #231f20; */
  --table-text: #fff;
  --ion-color-primary-rgb: 219, 176, 61;
  --ion-color-primary-contrast: #fff;
  --market-row: #231f20;
  --active-text: #fff;
  --scorecard-bg: #231f20;
  --row-bg: #231f20;
  --profit-bg: #2f3a33;
  --loss-bg: #433737;
  --card: #19191b;
}
.ios body,
.md body {
  --ion-text-color:#000;
  --login-input-bg: #ddd;
  --login-card: url('../assets/images/common/loginlight.png');
  --common-dark: #fff;
  --date-color: rgba(0, 0, 0, 0.8);
  --color: #000;
  --color-text: #000;
  --input-bg: var(--ion-background);
  --bg1: #000;
  --bg2: #f6b942;
  --bg3: #fff;
  --bg4: rgba(255, 255, 255, 0.9);
  --bg5: rgba(255, 255, 255, 0.12);
  --text: #000;
  --text1: #000;
  --text2: #757575;
  --text3: #000;
  --text4: rgba(0, 0, 0, 0.8);
  --table-body-background: #f0ece2;
  --label-color: #000;
  --ion-background: #f0ece2;
  --profile-card: linear-gradient(180deg, #392727 0%, #100a0a 100%);
  --profile-tab: #2f2e2e;
  --modal-bg-img: url('../assets/images/common/modal1.png');
  --modal-bg-img2: url('../assets/images/common/modal11.png');
  --modal-bg-img1: #231f20;
  --filter-border: rgba(0, 0, 0, 0.2);
  --filter-border1: rgba(255, 255, 255, 0.1);
  --report-table-header: linear-gradient(180deg, #474747 0%, #121111 100%);
  --report-table-border-color: #734a4b;
  --disable: rgba(255, 255, 255, 0.2);
  --disable-text: #000;
  --table-border-color: #734a4b;
  /* --font-family: 'Istok Web', sans-serif; */
  --font-family: 'Poppins';
  --ion-card-bg: linear-gradient(180deg, #474747 -50%, #121111 130.04%),
    linear-gradient(0deg, #734a4b, #734a4b);
  --select-element-background:#f0ece2;
  --ion-header-background: linear-gradient(
    184.87deg,
    #3b3a3a 3.93%,
    #000000 108.65%
  );
  --text-color: #000;
  --ion-color-secondary: #fff;
  --ion-background-color-rgb: var(--ion-background);
  --ion-background-color: var(--ion-background);
  --sub-header-bg-color: #f6b942;

  --ion-background-secondary: rgba(255, 255, 255, 0.1);
  --ion-background-tertiary: #fff;
  --ion-text-light: #e4ebff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  /** Card Variables**/
  --ion-card-background: #231f20;
  --ion-card-background-secondary: #231f20;
  --ion-card-background-contrast: #e4ebff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* drawer */

  --ion-drawer-background: linear-gradient(180deg, #444444 0%, #111111 100%);
  --text-white: #000;
  /** Popover Variables**/
  --ion-popover-background: #000;
  --ion-popover--hover-background: #444444;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #231f20;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-content-background: #e4e8ed;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: var(--ion-background);
  --date-picker-header-button-background: #293b48;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #fff;

  /** Input Field Styles **/
  --input-background-color: rgba(255, 255, 255, 0.1);
  --input-text-color: #000;
  --table-header-text: #000;
  /* --odd-table-row: #231f20; */
  --table-text: #fff;
  --ion-color-primary-rgb: 219, 176, 61;
  --ion-color-primary-contrast: var(--text1);
  --market-row: #231f20;
  --active-text: #fff;
  --scorecard-bg: #231f20;
  --row-bg: #231f20;
  --profit-bg: #2f3a33;
  --loss-bg: #433737;
  --card: #ddd;
}
.ios body.green,
.md body.green {
  --ion-text-color: #fff;
  --color: #fff;
  --common-dark: #000;
  --input-bg: var(--ion-background);
  --bg1: #000;
  --ion-color-primary: #64d0b7;
  --bg2: #64d0b7;
  --bg3: #303030;
  --bg4: rgba(255, 255, 255, 0.1);
  --bg5: rgba(255, 255, 255, 0.12);
  --text: rgba(255, 255, 255, 0.4);
  --text1: rgba(255, 255, 255, 0.4);
  --text2: #8f8f8f;
  --text3: rgba(255, 255, 255, 0.6);
  --text4: rgba(255, 255, 255, 1);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --label-color: rgba(255, 255, 255, 0.8);
  --ion-background: #19191b;
  --profile-card: linear-gradient(180deg, #392727 0%, #100a0a 100%);
  --profile-tab: #2f2e2e;
  --modal-bg-img: url('../assets/images/common/modal1.png');
  --modal-bg-img2: url('../assets/images/common/modal11.png');
  --modal-bg-img1: #231f20;
  --filter-border: rgba(255, 255, 255, 0.1);
  --filter-border1: rgba(255, 255, 255, 0.1);
  --report-table-header: linear-gradient(180deg, #474747 0%, #121111 100%);
  --report-table-border-color: #734a4b;
  --disable: rgba(255, 255, 255, 0.2);
  --disable-text: #fff;
  --table-border-color: #734a4b;
  /* --font-family: 'Istok Web', sans-serif; */
  --font-family: 'Poppins';
  --ion-card-bg: linear-gradient(180deg, #474747 -50%, #121111 130.04%),
    linear-gradient(0deg, #734a4b, #734a4b);
  --select-element-background: #231f20;
  --ion-header-background: #19191b;

  --text-color: #fff;
  --ion-color-secondary: #303031;
  --ion-background-color-rgb: #19191b;
  --ion-background-color: #19191b;
  --sub-header-bg-color: #64d0b7;

  --ion-background-secondary: rgba(255, 255, 255, 0.1);
  --ion-background-tertiary: #fff;

  --ion-text-light: #e4ebff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  /** Card Variables**/
  --ion-card-background: #231f20;
  --ion-card-background-secondary: #231f20;
  --ion-card-background-contrast: #e4ebff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* drawer */

  --ion-drawer-background: linear-gradient(180deg, #444444 0%, #111111 100%);
  --text-white: #fff;
  /** Popover Variables**/
  --ion-popover-background: #000;
  --ion-popover--hover-background: #444444;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #231f20;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-content-background: #231f20;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #231f20;
  --date-picker-header-button-background: #231f20;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #fff;

  /** Input Field Styles **/
  --input-background-color: rgba(255, 255, 255, 0.1);
  --input-text-color: rgba(255, 255, 255, 0.4);
  --table-header-text: #fff;
  /* --odd-table-row: #231f20; */
  --table-text: #fff;
  --ion-color-primary-rgb: 219, 176, 61;
  --ion-color-primary-contrast: #fff;
  --market-row: #231f20;
  --active-text: #fff;
  --scorecard-bg: #231f20;
  --row-bg: #231f20;
  --profit-bg: #2f3a33;
  --loss-bg: #433737;
  --card: #19191b;
}
.ios body.purple,
.md body.purple {
  --ion-color-primary: #7033f5;
  --ion-text-color: #fff;
  --color: #fff;
  --common-dark: #0b071f;
  --input-bg: var(--ion-background);
  --bg1: #181429;
  --bg2: #7033f5;
  --bg3: #303030;
  --bg4: rgba(255, 255, 255, 0.1);
  --bg5: rgba(255, 255, 255, 0.12);
  --text: rgba(255, 255, 255, 0.4);
  --text1: rgba(255, 255, 255, 0.4);
  --text2: #8f8f8f;
  --text3: rgba(255, 255, 255, 0.6);
  --text4: rgba(255, 255, 255, 1);
  --table-body-background: #181429;
  --label-color: rgba(255, 255, 255, 0.8);
  --ion-background: #181429;
  --profile-card: linear-gradient(180deg, #392727 0%, #100a0a 100%);
  --profile-tab: #2f2e2e;
  --modal-bg-img: url('../assets/images/common/modal1.png');
  --modal-bg-img2: url('../assets/images/common/modal11.png');
  --modal-bg-img1: #231f20;
  --filter-border: rgba(255, 255, 255, 0.1);
  --filter-border1: rgba(255, 255, 255, 0.1);
  --report-table-header: linear-gradient(180deg, #474747 0%, #121111 100%);
  --report-table-border-color: #734a4b;
  --disable: rgba(255, 255, 255, 0.2);
  --disable-text: #fff;
  --table-border-color: #734a4b;
  /* --font-family: 'Istok Web', sans-serif; */
  --font-family: 'Poppins';
  --ion-card-bg: linear-gradient(180deg, #474747 -50%, #121111 130.04%),
    linear-gradient(0deg, #734a4b, #734a4b);
  --select-element-background: #231f20;
  --ion-header-background: #19191b;

  --text-color: #000;
  --ion-color-secondary: #303031;
  --ion-background-color-rgb: #19191b;
  --ion-background-color: #19191b;
  --sub-header-bg-color: #7033f5;

  --ion-background-secondary: #181429;
  --ion-background-tertiary: #fff;

  --ion-text-light: #e4ebff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  /** Card Variables**/
  --ion-card-background: #231f20;
  --ion-card-background-secondary: #231f20;
  --ion-card-background-contrast: #e4ebff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* drawer */

  --ion-drawer-background: linear-gradient(180deg, #444444 0%, #111111 100%);
  --text-white: #fff;
  /** Popover Variables**/
  --ion-popover-background: #000;
  --ion-popover--hover-background: #444444;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #231f20;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-content-background: #231f20;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #231f20;
  --date-picker-header-button-background: #231f20;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #fff;

  /** Input Field Styles **/
  --input-background-color: rgba(255, 255, 255, 0.1);
  --input-text-color: rgba(255, 255, 255, 0.4);
  --table-header-text: #fff;
  /* --odd-table-row: #231f20; */
  --table-text: #fff;
  --ion-color-primary-rgb: 219, 176, 61;
  --ion-color-primary-contrast: #fff;
  --market-row: #231f20;
  --active-text: #fff;
  --scorecard-bg: #231f20;
  --row-bg: #231f20;
  --profit-bg: #2f3a33;
  --loss-bg: #433737;
  --card: #19191b;
}

.ios body.blue,
.md body.blue {
  --ion-color-primary: #2979cd;
  --login-input-bg: #303031;
  --ion-header-background: #19191b;
  --ion-background: #19191b;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #303030;
  --ion-background-tertiary: #303032;
  --common-dark: #000;
  --text-1: rgba(0, 0, 0, 0.8);
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.6);
  --text2: rgba(0, 0, 0, 0.6);
  --text3: rgba(255, 255, 255, 0.6);
  --teble-head: rgba(255, 255, 255, 0.4);
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #262525;
  --footer-bg: #000;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  /* Scorecard */
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #231f20;
}
