.MuiDialog-root {
  .MuiDialogActions-root {
    // background: var(--dialog-content-background) !important;
  }
  .MuiDialog-container {
    .MuiIconButton-root{
      opacity: 1;
    }
    .MuiDialog-paper {
      
      .MuiDialogTitle-root {
        background: var(--table-body-background);
        color: var(--table-text);
        text-align: center;
        font-size: 16px !important;
        height: auto;
        @media screen and (min-width: 1200px) {
          height: 50px;
        }
        .MuiTypography-root {
          font-size: 16px !important;
          margin-top: -5px;
          font-weight: bold;
          font-family: var(--font-family);
        }
      }
      .modal-close-btn {
        opacity: 1 !important;
        svg {
          color: #fff;
          margin-top: -5px;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }

      .light-bg-title {
        background: var(--ion-text-light);
        color: var(--dialog-header-background);
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--dialog-header-background);
        }
      }

      .MuiDialogContent-root {
        padding: 2px !important;
        background: var(--dialog-content-background);
        .MuiButton-containedPrimary {
          color: var(--common-dark);
          text-transform: none;
          font-weight: 600;
          margin-bottom: 10px;
          background-color: var(--ion-color-primary);
        }
        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary);
        }
      }
    }
  }
}
.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogTitle-root .MuiTypography-root{
  color: var(--table-header-text) !important; 
}
.MuiButton-endIcon ,.MuiButton-iconSizeMedium,
.MuiButton-label{
  color: var(--common-dark);
}
.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .MuiButton-containedPrimary{
  width: 100%;
}