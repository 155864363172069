.reports-ctn {
  background-color: var(--common-dark);
  padding: 12px;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  position: relative;

  .ml-5 {
    margin-left: 7px;
  }
  .b-text {
    text-decoration: underline !important;
  }

  .MuiPaper-root {
    margin-right: 0.6rem;
    border: 1px solid var(--filter-border);
    background: var(--table-body-background) !important;
    border-radius: 12px !important;
    .MuiTableHead-root .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 12px !important;
        font-family: var(--font-family);
        color: var(--text) !important;
        border-bottom: 1px solid var(--filter-border) !important;
        text-transform: uppercase;
        padding: 4px !important;
        .MuiIconButton-root {
          padding: 0px;
          color: var(--text) !important;
          text-transform: uppercase;
        }
        @media only screen and (max-width: 720px) {
          font-size: 11px !important;
        }
      }
    }
    .MuiTableBody-root {
      br {
        content: '';
        display: block;
        font-size: 100%;
        height: 0.2em;
      }

      .lay-bg,
      .back-bg {
        .back-th {
          border-left: 5px solid var(--back-odd-background) !important;
        }
        .lay-th {
          border-left: 5px solid var(--lay-odd-background) !important;
        }

        .badge {
          font-size: 9px !important;
        }

        .MuiTableCell-body {
          font-size: 13px;
          border-spacing: 30px;
          font-weight: 500;
          font-family: var(--font-family);
          padding: 2px !important;
          // text-align: center;
          .MuiTableRow-root {
            // border-bottom: 1px solid var(--filter-border) !important;
          }
          .MuiTableRow-root:last-child {
            border-bottom: none;
          }
          .tooltip-icon,
          svg {
            color: var(--text-white);
            fill: var(--text-white) !important;
          }
          .flag {
            color: #ffc019;
            fill: #ffc019 !important;
            width: 30px;
          }
          .odds-ctn {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            color: var(--text-primary);
            .tooltip-icon,
            svg {
              color: var(--text-white);
              fill: var(--text-white) !important;
            }
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
      .loss-bg,
      .profit-bg {
        color: var(--text-primary) !important;
        border-top: 1px solid var(--filter-border);
        .MuiTableCell-body {
          // text-align: center;
          font-size: 12px;
          border-spacing: 30px;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 4px !important;
          @media only screen and (max-width: 720px) {
            font-size: 11px !important;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
        &.profit {
          color: var(--ion-color-success) !important;
        }
        &.loss {
          color: var(--ion-color-danger) !important;
        }
      }

      .row-bg {
        color: var(--text-primary) !important;
        // border-top: 1px solid var(--filter-border);

        .flag {
          color: #ffc019;
          fill: #ffc019 !important;
          width: 30px;
        }
        .MuiTableCell-body {
          // text-align: center;
          font-size: 12px;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 4px;
          @media only screen and (max-width: 720px) {
            font-size: 11px !important;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }

      .MuiTableRow-body {
        border-top: 1px solid var(--filter-border);
        font-size: 14px;
        .MuiTableCell-body {
          // text-align: center;
          font-size: 14px;
          font-family: var(--font-family);
          color: var(--text-primary) !important;
          padding: 4px;
          @media only screen and (max-width: 720px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .no-data-row {
    background-color: var(--table-body-background);
    width: 100%;
    border-bottom: 0px !important;
    height: 35px;
    padding-top: 15px;
    text-align: center;
    color: var(--text-color) !important;
    div,
    p,
    span {
      color: var(--text-color) !important;
    }
  }
}
.MuiTableRow-root {
  // border-bottom: 1px solid var(--filter-border) !important;
}
.reports-ctn {
  border-radius: 0px;

  .header-ctn {
    .img-page-title-row {
      display: flex;
      justify-content: left;
      flex-direction: row;
      align-items: center !important;
      img {
        margin-left: 5px;
        height: 30px;
        width: 30px;
        cursor: pointer;
      }

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 5px;
      }
      .img-ctn {
        img {
          margin-left: 5px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0em;
        color: var(--text-theme);
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        .img-ctn {
          img {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .prev-level-link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center !important;
    margin-bottom: 10px;
    // margin-top: 10px;
    font-family: var(--font-family) !important;
    background: var(--bg3);
    border-radius: 10px;
    padding: 4px;
    .filter-title {
      font-size: 14px;
      padding-left: 2px;
      color: var(--text-white) !important;
      @media only screen and (max-width: 720px) {
        font-size: 12px;
      }
    }
    .filter-item-button {
      display: flex;
      flex-direction: row;
      .button-small,
      .button-solid {
        height: 32px;
        color: var(--common-dark) !important;
        --border-radius: 12px !important;
      }
    }
    .select-text {
      font-size: 12px !important;
    }
    .filter-item {
      .filter-control,
      .native-input.sc-ion-input-ios,
      .input-control {
        background-color: var(--ion-background);
        color: var(--text) !important;
        width: 100%;
        height: 36px;
        font-family: var(--font-family) !important;
        border-radius: 8px;
        padding: 6px;
        font-size: 12px !important;
        &.readonly {
          background-color: #ccc;
        }
      }

      .filter-label {
        font-size: 13px;
        margin-top: -5px !important;
        color: var(--label-color);
        font-weight: var(--font-weight);
      }
    }
    .search-filter {
      .clear-search {
        position: absolute;
        font-size: 13px;
        color: var(--label-color);
        font-weight: var(--font-weight);
        font-family: var(--font-family) !important;
        right: 3px;
        color: var(--ion-color-danger);
        cursor: pointer;
        margin-top: -5px;
      }
      .search-filter-label {
        font-size: 13px;
        color: var(--label-color);
        font-weight: var(--font-weight);
        font-family: var(--font-family) !important;
        margin-top: -3px;
      }
    }
    .select-filter {
      font-size: 12px;
      width: 100%;
      // .select-filter-label {
      //   font-size: 13px;
      //   color: var(--label-color);
      //   font-weight: var(--font-weight);
      //   font-family: var(--font-family) !important;
      //   margin-top: -3px !important;
      // }
      .select-text {
        font-size: 12px !important;
      }
      .select-filter-control {
        background-color: var(--ion-background);
        color: var(--text);
        height: 34px;
        font-family: var(--font-family) !important;
        border-radius: 8px;
      }
    }
    .date-filter {
      width: 100%;
      font-size: 12px;
      .MuiFormHelperText-root.Mui-error {
        color: #f44336;
        position: absolute !important;
        bottom: -14px !important;
        font-size: 10px;
        display: none;
      }
      .date-filter-label {
        font-size: 13px;
        color: var(--label-color);
        font-weight: var(--font-weight);
        margin-top: 0.3rem;
      }
      .readonly {
        opacity: 0.6 !important;
      }
      .date-filter-control {
        width: 100%;
        .native-input.sc-ion-input-ios,
        .MuiInputBase-root {
          background-color: var(--ion-background);
          color: var(--text);
          width: 100%;
          height: 34px;
          font-family: var(--font-family) !important;
          border-radius: 8px;
          .MuiIconButton-root {
            filter: var(--date-icon);
            margin-right: 7px;
          }
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            padding: 4px 16px;
            font-size: 10px;
            color: var(--text) !important;
          }
        }
      }
    }
  }

  .content-ctn {
    border-radius: 6px;
  }
}

@media only screen and (max-width: 720px) {
  .reports-ctn {
    margin-top: -5px;
    .serch-mob-ctn {
      display: flex;
      flex-direction: row;
      .clear-search {
        font-size: 11px;
        color: var(--text);
      }
      .input-control {
        height: 35px;
        width: 100%;
        padding: 0 10px !important;
        background: var(--bg3) !important;
        border-radius: 10px !important;
        color: var(--text);
        border: 1px solid var(--filter-border) !important;
        outline: none;
        font-size: 13px;
        text-align: left;
        text-overflow: ellipsis;
        -webkit-appearance: none;
        margin-top: -1px !important;
        font-weight: lighter !important;
      }
      .go-button {
        display: flex;
        flex-direction: row;
        .Search-btn,
        .button-solid {
          color: var(--common-dark) !important;
          --border-radius: 6px !important;
          margin-top: 0px;
        }
      }
    }
    .header-ctn,
    .content-ctn {
      padding: 0 8px;
      .points-type-toggle {
        height: 32px;
        border-radius: 8px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .filters-row {
      display: flex;
      flex-wrap: wrap;

      .img-page-title-row {
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        text-decoration: none;
        .title-image {
          height: 35px;
          width: 40px;
          margin-right: 5px;
        }
        .title {
          // margin-left: 10px;
          font-size: 18px;
          font-style: italic;
          font-weight: 700;
          line-height: 45px;
          letter-spacing: 0em;
          color: var(--text-theme);
          text-transform: uppercase;
          font-weight: bold;
          display: flex;
          .prev-level-link {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .select-filter {
        font-size: 12px;
        margin: 0.3rem;
        flex: 0 0 47%;
        .select-filter-label {
          font-size: 12px;
        }
        .select-filter-control {
          margin-top: 0.1rem;
          width: 100%;
          font-size: 10px;
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        font-size: 12px;
        .date-filter-label {
          font-size: 12px;
        }
        .date-filter-control {
          margin-top: 0%;
          .MuiInputBase-root {
            height: 30px;
            width: 100%;
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 16px;
            }
          }
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .img-page-title-row {
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        display: flex;
        align-items: center;
        .title-image {
          height: 25px;
          width: 25px;
          margin-right: 5px;
          margin-left: 0px;
        }
        .title {
          font-size: 13px;

          line-height: 22px;
        }
      }
    }
  }

  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.date-filter-control {
  .MuiInputBase-root {
    border: none;
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--text-primary) !important;
      border: none !important;
    }
  }
}
.input-wrapper {
  font-family: var(--font-family) !important;
}
.sc-ion-label-ios-h {
  font-family: var(--font-family) !important;
  font-weight: bold;
}

.header-reports {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // @media only screen and (max-width: 720px) {
  //   margin-top: 30px;
  // }
  .report-title {
    display: flex;
    flex-direction: row;

    .active {
      color: var(--text-white) !important;
    }
    .curve {
      height: 50px;
      margin-right: 40px;
      text-decoration: none;
      color: var(--text);
      margin-top: 30px;
      .arrow {
        position: relative;
        color: var(--text-white);
        min-width: 230px;
        height: 50px;
        background-color: var(--common-dark);
        line-height: 50px;
        margin-bottom: 30px;
        text-align: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 35px;
        padding-left: 12px;
        font-size: 16px;
        font-weight: 400 !important;
        .color-active {
          color: var(--ion-color-primary);
          margin-left: 10px;
        }
      }

      .arrow-right:after {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        right: -38px;
        top: 0;
        border-top-left-radius: 10px;
        border-right: 60px solid transparent;
        border-bottom: 60px solid var(--common-dark);
      }
    }
  }
  .search-ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .search-filter {
      .clear-search {
        position: absolute;
        font-size: 11px;
        color: var(--text);
        right: 2px;
        color: var(--ion-color-danger);
        cursor: pointer;
        margin-top: -15px;
      }
    }
    .img-ctn {
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      border: 1px solid var(--filter-border);
      background: var(--bg3);
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.date-color {
  color: var(--date-color);
}
