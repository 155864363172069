.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 0 20px;
  padding: 10px;
  .MuiFormHelperText-root {
    color: #ff0000;
  }
  .MuiIconButton-root{
    color: #fff;
  }
  .mt-3{
    margin-top: 5px;
  }
  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 26px;
    width: 100%;
    .MuiOutlinedInput-root{
      border-radius: 8px !important;
    }
    .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        font-size: 13px;
        font-family: var(--font-family) !important;
        background-color: var(--input-bg) !important;
        border-radius: 8px !important;
        border: 1px solid var(--filter-border) !important;
      }
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 100%;
    height: 48px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .accept-terms-input {
    padding-top: 14px;
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;
      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-text-color);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-text-color) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}
.user-name,
.pwd-field {
  width: 100%;
  height: 48px;
  width: 100%;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 100%;
  }
}

.change-pwd-ctn {
  // margin-left: 1%;
  width: 50%;
  @media screen and (max-width: 720px) {
    width: 95%;
  }
  .submit-form-btn {
    width: 100%;
    background-color: var(--ion-color-primary) !important;
    margin-top: 10px !important;
    border-radius: 10px;
    .MuiButton-label {
      color: var(--text-common-dark) !important;
      font-size: 14px;
      font-weight: 900;
      text-transform: capitalize;
      .icon {
        margin-right: 10px;
      }
    }
  }

  .change-pass-ctn,
  form {
    @media only screen and (max-width: 720px) {
      width: 80%;
      margin-left: 2%;
      margin-right: 4%;
    }
    // padding-left: 2%;
    padding-right: 2%;
    .form-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      .input-label {
        font-size: 14px;
        font-family: var(--font-family);
        color: var(--ion-text-light);
        margin-top: 10px;
      }
      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        font-size: 13px;
        font-family: var(--font-family) !important;
        background-color: var(--input-bg) !important;
        border-radius: 8px !important;
        border: 1px solid var(--filter-border) !important;
      }
    }

    .condition-ctn {
      margin-top: 25px;
      .title {
        font-size: 16px;
        line-height: 15px;
        color: var(--ion-text-light);
      }
      .detail {
        font-size: 12px;
        color: #ff0000;
        margin-top: 8px;
        font-family: var(--font-family);
        line-height: 20px;
      }
    }
    .MuiDialogActions-root {
      justify-content: flex-end !important;
      .MuiButton-label {
        color: var(--ion-text-light);
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .change-pwd-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    width: 100%;
    .change-pass-ctn,
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .form-input {
        margin-top: 15px;
      }
      .submit-form-btn {
        background-color: var(--ion-color-primary) !important;
        margin-top: 1% !important;
        border-radius: 10px;
        .MuiButton-label {
          color: var(--text-common-dark) !important;
          font-size: 16px;
        }
      }
      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        font-size: 13px;
        font-family: var(--font-family) !important;
        background-color: var(--input-bg);
      }
      .form-input {
        margin-top: 15px;
        input::placeholder {
          color: var(--text);
          opacity: 1;
          font-weight: lighter !important;
          font-size: 16px;
        }
      }
    }
  }
}
