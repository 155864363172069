
.MuiSelect-select.MuiSelect-select {
  font-family: var(--font-family) !important;
  .select-text{
    font-size: 12px !important;
  }
  input {
    font-family: var(--font-family) !important;
    font-size: 12px !important;
  }
}
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
  font-size: 12px !important;
  color: var(--text) !important;
}
.ion-select-popover {
  font-family: var(--font-family) !important;
  .ion-list {
    font-family: var(--font-family) !important;
    font-size: 11px !important;
  }
}
